import React from 'react';
import { TEAMS } from './constants';

export const TOOLS_CONTENT = {
  default: {
    title: 'DEFAULT TOOLS CONTENT',
    content: <div />,
    links: []
  }
}

export const COLUMN_DEFINITIONS = [
  {
    id: 'icon',
    cell: item => <img
      className="team_icon"
      src={TEAMS.find(team => team.id === item.team_id).icon}
      alt={`${item.team_name} Team Icon`}
    />,
    header: '',
    minWidth: '25px',
    maxWidth: '25px'
  },
  {
    id: 'username',
    cell: item => item.username,
    header: 'User',
    sortingField: 'username'
  },
  {
    id: 'local_office',
    cell: item => item.local_office,
    header: 'Local Office',
    sortingField: 'local_office'
  }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 8,
  visibleContent: [ 'icon', 'username', 'team_name', 'local_office', 'total_activity_time' ],
  wraplines: false
};
