import React from 'react';
import { TopNavigation } from '@awsui/components-react';

import logo from '../../images/e3.png';
import '../../styles/top-navigation.scss';

import { withRouter } from './hooks/withRouter';
import { Auth } from '@aws-amplify/auth';

const CustomTopNavigation = ({ user }) => {
  return (
    <div id="h">
      <TopNavigation
        identity={{
          href: '/',
          title: 'WWPS Wellness Challenge - Space Adventure',
          logo: {
            src: logo,
            alt: 'WWPS Wellness Challenge Logo'
          }
        }}
        i18nStrings={{
          overflowMenuTriggerText: 'More'
        }}
        utilities={ !user ? [] : [
          {
            type: 'button',
            text: user.username,
            iconName: 'user-profile'
          },
          {
            type: 'button',
            text: `Team ${user.team_name}`,
            iconName: 'bug'
          },
          {
            type: 'button',
            text: 'Sign Out',
            iconName: 'lock-private',
            onClick: () => Auth.signOut()
          }
        ]}
      />
    </div>
  );
}

export default withRouter(CustomTopNavigation);
