import { getDaysInMonth } from "../libs/utils";

export const EVENT_START_DATE = "2023-06-01";
export const EVENT_END_DATE = "2023-06-30";
export const EVENT_MONTH = 1;
export const EVENT_YEAR = 2023;

export const EVENT_DATES = [ ...getDaysInMonth(EVENT_YEAR, EVENT_MONTH), EVENT_END_DATE ];

export const MINIMUM_ACTIVITY_TIME = 1;
export const MAXIMUM_ACTIVITY_TIME = 60 * 24;

export const SUPPORTED_ACTIVITIES = [
  { label: "Walking", value: "walking" },
  { label: "Running", value: "running" },
  { label: "Cycling", value: "cycling" },
  { label: "Swimming", value: "swimming" },
  { label: "Yoga / Pilates", value: "yoga" },
  { label: "Meditating", value: "meditating" },
  { label: "Gym", value: "gym" },
  { label: "Rowing", value: "rowing" },
  { label: "Golf", value: "golf" },
  { label: "Music", value: "music" },
  { label: "Art", value: "art" },
  { label: "Volunteering", value: "volunteering" }
];

export const DEFAULT_DURATIONS = [
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
  { value: 30 },
  { value: 45 },
  { value: 60 }
]

export const PLANETS = [
  { id: "earth", name: "Earth", pos: [74, 287], image: require("../images/planets/earth.png"), size: [ 50, 50] },
  { id: "iss", name: "International Space Station", pos: [204, 168], image: require("../images/planets/iss.png"), size: [ 50, 50] },
  { id: "moon", name: "The Moon", pos: [268, 354], image: require("../images/planets/moon.png"), size: [ 48, 35] },
  { id: "venus", name: "Venus", pos: [354, 196], image: require("../images/planets/venus.png"), size: [ 48, 48] },
  { id: "mercury", name: "Mercury", pos: [506, 389], image: require("../images/planets/mercury.png"), size: [ 40, 37] },
  { id: "sun", name: "The Sun", pos: [604, 260], image: require("../images/planets/thesun.png"), size: [ 70, 64] },
  { id: "mars", name: "Mars", pos: [764, 402], image: require("../images/planets/mars.png"), size: [ 48, 48] },
  { id: "jupiter", name: "Jupiter", pos: [842, 162], image: require("../images/planets/jupiter.png"), size: [ 60, 60] },
  { id: "saturn", name: "Saturn", pos: [1026, 380], image: require("../images/planets/saturn.png"), size: [ 80, 60] },
  { id: "uranus", name: "Uranus", pos: [1143, 141], image: require("../images/planets/uranus.png"), size: [ 50, 50] },
  { id: "neptune", name: "Neptune", pos: [1306, 279], image: require("../images/planets/neptune.png"), size: [ 50, 50] },
  { id: "pluto", name: "Pluto", pos: [1432, 159], image: require("../images/planets/pluto.png"), size: [ 50, 50] },
  { id: "jwst", name: "James Webb Space Telescope", pos: [1532, 378], image: require("../images/planets/jwst.png"), size: [ 50, 50] },
];

export const TEAMS = [
  { id: "amazon_athena", name: "Amazon Athena", icon: require("../images/teams/athena.png") },
  { id: "amazon_aurora", name: "Amazon Aurora", icon: require("../images/teams/aurora.png") },
  { id: "amazon_braket", name: "Amazon Braket", icon: require("../images/teams/braket.png") },
  { id: "amazon_cloudformation", name: "Amazon CloudFormation", icon: require("../images/teams/cloudformation.png") },
  { id: "amazon_cloudwatch", name: "Amazon CloudWatch", icon: require("../images/teams/cloudwatch.png") },
  { id: "amazon_codewhisperer", name: "Amazon CodeWhisperer", icon: require("../images/teams/codewhisperer.png") },
  { id: "amazon_cognito", name: "Amazon Cognito", icon: require("../images/teams/cognito.png") },
  { id: "amazon_comprehend", name: "Amazon Comprehend", icon: require("../images/teams/comprehend.png") },
  { id: "amazon_connect", name: "Amazon Connect", icon: require("../images/teams/connect.png") },
  { id: "amazon_documentdb", name: "Amazon DocumentDB", icon: require("../images/teams/documentdb.png") },
  { id: "amazon_dynamodb", name: "Amazon DynamoDB", icon: require("../images/teams/dynamodb.png") },
  { id: "amazon_ec2", name: "Amazon EC2", icon: require("../images/teams/ec2.png") },
  { id: "amazon_emr", name: "Amazon EMR", icon: require("../images/teams/emr.png") },
  { id: "amazon_forecast", name: "Amazon Forecast", icon: require("../images/teams/forecast.png") },
  { id: "amazon_guardduty", name: "Amazon GuardDuty", icon: require("../images/teams/guardduty.png") },
  { id: "amazon_healthlake", name: "Amazon HealthLake", icon: require("../images/teams/healthlake.png") },
  { id: "amazon_kendra", name: "Amazon Kendra", icon: require("../images/teams/kendra.png") },
  { id: "amazon_kinesis", name: "Amazon Kinesis", icon: require("../images/teams/kinesis.png") },
  { id: "amazon_lex", name: "Amazon Lex", icon: require("../images/teams/lex.png") },
  { id: "amazon_lightsail", name: "Amazon Lightsail", icon: require("../images/teams/lightsail.png") },
  { id: "amazon_location", name: "Amazon Location", icon: require("../images/teams/location.png") },
  { id: "amazon_lookout", name: "Amazon Lookout", icon: require("../images/teams/lookout.png") },
  { id: "amazon_macie", name: "Amazon Macie", icon: require("../images/teams/macie.png") },
  { id: "amazon_monitron", name: "Amazon Monitron", icon: require("../images/teams/monitron.png") },
  { id: "amazon_neptune", name: "Amazon Neptune", icon: require("../images/teams/neptune.png") },
  { id: "amazon_opensearch", name: "Amazon OpenSearch", icon: require("../images/teams/opensearch.png") },
  { id: "amazon_personalize", name: "Amazon Personalize", icon: require("../images/teams/personalize.png") },
  { id: "amazon_polly", name: "Amazon Polly", icon: require("../images/teams/polly.png") },
  { id: "amazon_rds", name: "Amazon RDS", icon: require("../images/teams/rds.png") },
  { id: "amazon_redshift", name: "Amazon Redshift", icon: require("../images/teams/redshift.png") },
  { id: "amazon_rekognition", name: "Amazon Rekognition", icon: require("../images/teams/rekognition.png") },
  { id: "amazon_route53", name: "Amazon Route53", icon: require("../images/teams/route53.png") },
  { id: "amazon_s3", name: "Amazon S3", icon: require("../images/teams/s3.png") },
  { id: "amazon_sagemaker", name: "Amazon SageMaker", icon: require("../images/teams/sagemaker.png") },
  { id: "amazon_sqs", name: "Amazon SQS", icon: require("../images/teams/sqs.png") },
  { id: "amazon_stepfunctions", name: "Amazon StepFunctions", icon: require("../images/teams/stepfunctions.png") },
  { id: "amazon_timestream", name: "Amazon Timestream", icon: require("../images/teams/timestream.png") },
  { id: "amazon_workspaces", name: "Amazon Workspaces", icon: require("../images/teams/workspaces.png") },
  { id: "aws_amplify", name: "AWS Amplify", icon: require("../images/teams/amplify.png") },
  { id: "aws_appsync", name: "AWS AppSync", icon: require("../images/teams/appsync.png") },
  { id: "aws_appstream", name: "AWS AppStream", icon: require("../images/teams/appstream.png") },
  { id: "aws_cloudtrail", name: "AWS CloudTrail", icon: require("../images/teams/cloudtrail.png") },
  { id: "aws_codebuild", name: "AWS CodeBuild", icon: require("../images/teams/codebuild.png") },
  { id: "aws_config", name: "AWS Config", icon: require("../images/teams/config.png") },
  { id: "aws_controltower", name: "AWS Control Tower", icon: require("../images/teams/controltower.png") },
  { id: "aws_deeplens", name: "AWS DeepLens", icon: require("../images/teams/deeplens.png") },
  { id: "aws_deepracer", name: "AWS DeepRacer", icon: require("../images/teams/deepracer.png") },
  {
    id: "aws_elasticbeanstalk",
    name: "AWS Elastic Beanstalk",
    icon: require("../images/teams/elasticbeanstalk.png"),
  },
  { id: "aws_fargate", name: "AWS Fargate", icon: require("../images/teams/fargate.png") },
  { id: "aws_iam", name: "AWS IAM", icon: require("../images/teams/iam.png") },
  { id: "aws_iotcore", name: "AWS IoT Core", icon: require("../images/teams/iotcore.png") },
  { id: "aws_lambda", name: "AWS Lambda", icon: require("../images/teams/lambda.png") },
  { id: "aws_organizations", name: "AWS Organizations", icon: require("../images/teams/organizations.png") },
  { id: "aws_outposts", name: "AWS Outposts", icon: require("../images/teams/outposts.png") },
  { id: "aws_shield", name: "AWS Shield", icon: require("../images/teams/shield.png") },
];