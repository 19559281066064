import React, { useEffect, useState, useRef } from "react";
import { Container, Header } from "@awsui/components-react";

import L from "leaflet";
import { Lethargy } from "lethargy";

import challengePathPoints from "../../resources/challenge_path_points.json";
import { PLANETS } from "../../resources/constants";
import rocket from "../../images/rocket.png";

import "leaflet/dist/leaflet.css";
import "../../styles/tracker_map.scss";

const lethargy = new Lethargy(7, 50, 0.05);
const isInertialScroll = (e) => lethargy.check(e) === false;

L.Map.ScrollWheelZoom.prototype._onWheelScroll = function (e) {
  L.DomEvent.stop(e);
  if (isInertialScroll(e)) return;

  this._delta += L.DomEvent.getWheelDelta(e);
  this._lastMousePos = this._map.mouseEventToContainerPoint(e);
  this._performZoom();
};

const MapChart = ({ loading, points }) => {
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    if (markers.length !== points.length) setMarkers(points);
  }, [points, markers.length]);

  useEffect(() => {
    const container = L.DomUtil.get(mapRef.current);
    if (container != null) container._leaflet_id = null;
    if (!container) return;

    let map = L.map('image-map', {
      center: [-270, 540],
      zoom: 0,
      minZoom: -1,
      maxZoom: 1,
      zoomSnap: 1,
      crs: L.CRS.Simple,
      renderer: L.svg({ padding: 100 })
    });

    const fg_planets = addPlanetMarkersToMap(map);
    const fg_route = addRouteToMap(map);
    const fg_markers = addMarkersToMap(map);
    if (!!fg_markers) {
      // map.panTo(fg_markers.getBounds().getCenter())
      map.fitBounds(fg_markers.getBounds().pad(1));
    }

    let customMarker;
    map.on('click', (pnt) => {
      if (!!customMarker) customMarker.remove()
      const icon = L.icon({
        iconUrl: rocket,
        iconSize: [ 30, 30 ]
      })
      customMarker = new L.marker(pnt.latlng, { icon: icon });
      customMarker.addTo(map);
    });

    return () => {
      map.remove();
    };
  }, [markers]);

  const addPlanetMarkersToMap = map => {
    const planetMarkerGroup = new L.FeatureGroup();

    PLANETS.forEach(planet => {
      const swPoint = [planet.pos[0] - (planet.size[0] / 2), planet.pos[1] + (planet.size[1] / 2)];
      const nePoint = [planet.pos[0] + (planet.size[0] / 2), planet.pos[1] - (planet.size[1] / 2)];

      let sw = map.unproject(swPoint, map.getMaxZoom() - 1);
      let ne = map.unproject(nePoint, map.getMaxZoom() - 1);
      let bounds = new L.LatLngBounds(sw, ne);
      L.imageOverlay(planet.image, bounds).addTo(planetMarkerGroup);

      const centre = map.unproject(planet.pos, map.getMaxZoom() - 1)
      const marker = new L.circle(centre, { radius: 0, color: 'white' });
      marker.bindTooltip(planet.name,
        {
          permanent: true,
          direction: 'center',
          offset: [0, 40]
        }
      );
      marker.addTo(planetMarkerGroup);
    });

    planetMarkerGroup.addTo(map);
    return planetMarkerGroup;
  }

  const addRouteToMap = map => {
    const routeGroup = new L.FeatureGroup().addTo(map);
    const pathPoints = challengePathPoints.map(point => map.unproject(point, map.getMaxZoom() - 1));
    const route = L.polyline(pathPoints, { color: 'white', dashArray: '5', weight: 2, opacity: 0.5 })
    route.addTo(routeGroup);
    return routeGroup;
  }

  const addMarkersToMap = map => {
    if (markers.length === 0) return;
    
    const markerGroup = new L.FeatureGroup().addTo(map);
    const pathPoints = challengePathPoints.map(point => map.unproject(point, map.getMaxZoom() - 1));

    markers.forEach(point => {
      const closestPoint = Math.ceil((pathPoints.length - 1) * point.progress)
      const icon = L.icon({
        className: 'custom-marker',
        iconUrl: point.iconUrl,
        iconSize: [ 25, 25 ]
      })
      const marker = new L.marker(pathPoints[closestPoint], { icon: icon });
      marker.bindPopup(point.name);
      marker.setZIndexOffset(closestPoint * 10); // large number for markers further along the path
      marker.addTo(markerGroup);
    });
    return markerGroup;
  }

  return (
    <Container loading={loading} header={<Header variant="h2">Progress Map</Header>}>
      <div className="map-container">
        <div id='image-map' ref={mapRef}></div>
      </div>
    </Container>
  );
};

export default MapChart;
