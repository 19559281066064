import { Header, Button, ButtonDropdown, SpaceBetween } from '@awsui/components-react';
import { useNavigate } from 'react-router-dom';

const PageHeader = ({ refresh }) => {
  const navigate = useNavigate();
  const buttons = [
    {
      text: 'Log an Activity',
      onClick: () => navigate(`/submit`, { replace: true })
    }
  ];

  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={() => refresh()} iconName="refresh" />
          {buttons.map((button, key) =>
            !button.items ? (
              <Button onClick={button.onClick} disabled={button.disabled || false} key={key}>
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key}>
                {button.text}
              </ButtonDropdown>
            )
          )}
        </SpaceBetween>
      }
    >
      Team Tracker
    </Header>
  );
};

export default PageHeader;