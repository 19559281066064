import { Header, Button, ButtonDropdown, SpaceBetween, Input, FormField, Box } from '@awsui/components-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PageHeader = ({ onChange, refresh }) => {
  const navigate = useNavigate();
  const buttons = [
    {
      text: 'Log an Activity',
      onClick: () => navigate(`/submit`, { replace: true })
    }
  ];

  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Box variant='p'>Number of teams to display</Box>
          <Slider onChange={onChange} />
          <Button onClick={() => refresh()} iconName="refresh" />
          {buttons.map((button, key) =>
            !button.items ? (
              <Button onClick={button.onClick} disabled={button.disabled || false} key={key}>
                {button.text}
              </Button>
            ) : (
              <ButtonDropdown items={button.items} key={key}>
                {button.text}
              </ButtonDropdown>
            )
          )}
        </SpaceBetween>
      }
    >
      Team Leaderboard
    </Header>
  );
};

export default PageHeader;

const Slider = ({ onChange }) => {
  const [value, setValue] = useState(25);

  const validateInput = newValue => {
    const val = Math.max(1, newValue);
    setValue(val);
    onChange(val);
  }

  return (
    <Input
      className="team-number-selection"
      type="number"
      value={value}
      onChange={({ detail }) => validateInput(detail.value)}
    />
  )
}