import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth'
import API from '@aws-amplify/api';

import '../styles/App.css';

import { TEAMS } from '../resources/constants';

import Home from './Home';
import Submit from './submit/Submit';
import Activities from './activities/Activities';
import TeamTracker from './team_tracker/TeamTracker';
import FourZeroFour from './FourZeroFour';
import TeamLeaderboard from './team_leaderboard/TeamLeaderboard';
import Login from './Login';
import Loading from './common/Loading';

const App = () => {
  const [user, setUser] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(
    () => {
      getUser().then(userData => {
        if (!!userData) fetchTeam(userData.username);
        else setCheckingAuth(false);
      });
    },
    []
  );

  useEffect(
    () => {
      if (!!user) setCheckingAuth(false);
    },
    [user]
  );

  const fetchTeam = async username => {
    setCheckingAuth(true);
    // usernames from custom ID providers are prepended with MidwaySAML_
    username = username.split("_")[1]
    const user_obj = await API.get("primary", `/users/${username}`);
    const team = TEAMS.find(t => t.id === user_obj.team_id);
    
    setUser({
      username: username,
      team_id: team.id,
      team_name: team.name
    });
  }

  const getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    }
    catch (e) {
      return null
    }
  }
  const handleSignIn = async () => {
    await Auth.federatedSignIn({customProvider: "MidwaySAML"})
    getUser().then(_user => {
      if (!user) return;
      fetchTeam(_user.username);
    }).catch(err => console.error(err));
  }

  return checkingAuth ? (
    <Loading />
  ) : (
    !user ? (
      <Login signIn={handleSignIn} />
    ) : (
      <>
        <Routes>
          <Route path="/" element={<Home user={user} />}  />
          <Route path="/submit" element={<Submit user={user} />} />
          <Route path="/activities" element={<Activities user={user} />} />
          <Route path="/team_tracker" element={<TeamTracker user={user} />} />
          <Route path="/team_leaderboard" element={<TeamLeaderboard user={user} />} />
          <Route path="*" element={<FourZeroFour user={user} />} />
        </Routes>
      </>
    )
  );
};

export default App;
