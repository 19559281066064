let config =  {
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_QaCdyw7Uw",
    IDENTITY_POOL_ID: "us-west-2:feda1eb7-a213-43ec-8454-402e91d031dc",
    APP_CLIENT_ID: "4biujrk6df5v2vsdks15rdtev5",
    OAUTH: {
      domain: "wwps-wbc-auth-gamma.auth.us-west-2.amazoncognito.com",
      scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: "https://molepete.people.aws.dev",
      redirectSignOut: "https://molepete.people.aws.dev",
      responseType: "code"
    }
  },
  api: {
    primary: {
      URL: "https://6thfw1q4u7.execute-api.us-west-2.amazonaws.com/prod",
      REGION: "us-west-2"
    }
  }
}

export default config;