import React from 'react';
import { ProgressBar } from '@awsui/components-react';
import { TEAMS } from './constants';

export const INITIAL_COORDS = [0, 0];

export const INITIAL_ZOOM = 1;

export const TOOLS_CONTENT = {
  default: {
    title: 'DEFAULT TOOLS CONTENT',
    content: <div />,
    links: []
  }
}

export const COLUMN_DEFINITIONS = [
  {
    id: 'icon',
    cell: item => <img
      className="team_icon"
      src={TEAMS.find(team => team.id === item.team_id).icon}
      alt={`${item.team_name} Team Icon`}
    />,
    header: '',
    minWidth: '50px',
    maxWidth: '50px'
  },
  {
    id: 'id',
    cell: item => item.team_id,
    header: 'Team Id',
    sortingField: 'id'
  },
  {
    id: 'name',
    cell: item => item.team_name,
    header: 'Team Name',
    sortingField: 'name'
  },
  {
    id: 'source',
    cell: item => item.currentLocation,
    header: 'Source',
    sortingField: 'source'
  },
  {
    id: 'destination',
    cell: item => item.nextLocation,
    header: 'Destination',
    sortingField: 'destination'
  },
  {
    id: 'current_progress',
    cell: item => <ProgressBar value={item.totalJourneyProgress * 100} variant="key-value" />,
    header: 'Total Journey Progress',
    sortingField: 'current_progress'
  }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main data source properties',
    options: [
      { id: 'name', label: 'Name', editable: true },
      { id: 'icon', label: 'Icon', editable: true },
      { id: 'source', label: 'Source', editable: true },
      { id: 'destination', label: 'Destination', editable: true },
      { id: 'current_progress', label: 'Current Progress', editable: true },
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Items' },
  { value: 25, label: '25 Items' },
  { value: 50, label: '50 Items' }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 25,
  visibleContent: [ 'icon', 'name', 'source', 'destination', 'current_progress' ],
  wraplines: false
};
