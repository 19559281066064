import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form, Header, SpaceBetween } from '@awsui/components-react';

import { INITIAL_STATE } from '../../resources/submit';

import validations from './_validations.jsx';
import { CustomAttributeEditor, CustomDatePicker } from './form_fields/form_fields.jsx';
import { validateField } from '../common/ValidationRules';

const ActivityForm = ({ handleSubmit, submitted }) => {
  const navigate = useNavigate()

  const [date, setDate] = useState(INITIAL_STATE.activity_date);
  const [activities, setActivities] = useState(INITIAL_STATE.activities);

  const validate = () => {
    const checkDate = !validateField(validations.activity_date, date);
    const checkEntries = !validateField(validations.activities, activities);
    return (checkDate && checkEntries)
  }

  const onSubmit = () => {
    if (!validate()) return

    handleSubmit({
      activity_date: date,
      activities: activities
    });
  };

  return (
    <Form
      header={<Header variant="h1">Log an Activity</Header>}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={() => navigate("/activities")} variant="link">
            Cancel
          </Button>
          <Button
            formAction="submit"
            disabled={submitted}
            onClick={() => onSubmit()}
            variant="primary"
          >
            Submit
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <Container header={<Header>Activity Settings</Header>}>
          <SpaceBetween size="l">
            <CustomDatePicker value={date} setValue={setDate} validationRules={validations['activity_date']} />
            <CustomAttributeEditor value={activities} setValue={setActivities} validationRules={validations['activities']} />
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </Form>
  );
};

export default ActivityForm;