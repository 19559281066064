import { formatDate } from "../../libs/utils";

export const validateField = (fieldRules, value) => {
  for (let rule of fieldRules) {
    if (!rule.validate(value)) return rule.message
  }
}

const createValidationRule = (errorMessage, validateFunction) => ({
  message: errorMessage,
  validate: validateFunction
});

const createDependencyValidationRule = (field, errorMessage, validateFunction) => ({
  field: field,
  message: errorMessage,
  validate: validateFunction
})

export const RequiredRule = message => createValidationRule(
  message,
  value => value.length !== 0
);

export const EitherFieldRequiredRule = (field, message) => createDependencyValidationRule(
  field,
  message,
  (value, otherValue) => value.length !== 0 || otherValue.length !== 0
)

export const AlphanumericRule = (message, regex) => createValidationRule(
  message,
  value => value.search(regex) === 0
);

export const DoesNotExistRule = (list, message) => createValidationRule(
  message,
  value => !list.includes(value)
);

export const EnsureExistsRule = (list, message) => createValidationRule(
  message,
  value => list.includes(value)
);

export const MinimumLengthRule = (characters, message) => createValidationRule(
  message,
  value => value.length >= characters
);

export const ValidJsonRule = message => createValidationRule(
  message,
  value => {
    try { JSON.parse(value) }
    catch (e) { return false }
    return true;
  }
);

export const NotDefaultJson = (initial, message) => createValidationRule(
  message,
  value => value !== JSON.stringify(initial, null, '\t')
);

export const NotEmptyJson = message => createValidationRule(
  message,
  value => value !== JSON.stringify({}, null, '\t')
);

export const ValidJsonTypes = (types, message) => createValidationRule(
  message,
  value => {
    const columns = JSON.parse(value);
    const valid = Object.values(columns).every(val => types.includes(val))
    return valid;
  }
);

export const GreaterThanRule = (threshold, message) => createValidationRule(
  message,
  value => value >= threshold
);

export const LessThanRule = (threshold, message) => createValidationRule(
  message,
  value => value <= threshold
);


export const GreaterThanFieldRule = (field, message) => createDependencyValidationRule(
  field,
  message,
  (value, otherValue) => parseInt(value) >= parseInt(otherValue)
);

export const LessThanFieldRule = (field, message) => createDependencyValidationRule(
  field,
  message,
  (value, otherValue) => parseInt(value) <= parseInt(otherValue)
);

export const FieldEntryNotEmpty = (field, message) => createValidationRule(
  message,
  value => value.length !== 0
)

export const WithinDateRangeRule = (startDate, endDate, message) => createValidationRule(
  message,
  value => {
    const date = new Date(value);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return (date >= start && date < end);
  }
)

export const NotFutureDateRule = (message) => createValidationRule(
  message,
  value => {
    const date = new Date(value);
    const today = new Date();
    return date <= today;
  }
)

export const AttributeGreaterThanRule = (attribute, threshold, message) => createValidationRule(
  message,
  value => value.every(val => val[attribute] > threshold)
)

export const AttributeGreaterThanOrEqualToRule = (attribute, threshold, message) => createValidationRule(
  message,
  value => value.every(val => val[attribute] >= threshold)
)

export const AttributeLessThanRule = (attribute, threshold, message) => createValidationRule(
  message,
  value => value.every(val => val[attribute] < threshold)
)

export const AttributeNotMatch= (attribute, match, message) => createValidationRule(
  message,
  value => value.every(val => val[attribute] !== match)
)